// InjectedHits.js
import React, { useEffect, useState } from 'react';
import { connectInfiniteHits } from 'react-instantsearch-dom';
import { ConnectInjectedInfiniteHits } from './ConnectInjectedInfiniteHits';
import Results from '../SearchExperiences/Results';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Text } from 'react-localize';
import { Typography } from '@mui/material';
import isEqual from 'lodash/isEqual';

export const InjectedInfiniteHits = connectInfiniteHits(
  ConnectInjectedInfiniteHits(({
    injectedInfiniteHits,
    numberOfHits,
    showNoResult,
    hasMore,
    refineNext,
    // eslint-disable-next-line no-unused-vars
    refinePrevious,
    ...props }) => {
    const [hits, setHits] = useState([]);
    const [tilesCount, setTilesCount] = useState(0);
    useEffect(() => {
      if (!isEqual(injectedInfiniteHits, hits)) {
        setHits(injectedInfiniteHits);
      }

      const missingHitsBookingTypeId = new Set();
      const hitsWithAdTile = (Array.from(new Set(injectedInfiniteHits
        .map(({ bookingTypeId }) => bookingTypeId)))) || [];
      const hitsBookingTypeId = (Array.from(new Set(
        props.hits.map(({ bookingTypeId }) => bookingTypeId)))) || [];
      (hitsBookingTypeId || []).forEach(hit =>
        !(hitsWithAdTile || []).includes(hit) && missingHitsBookingTypeId.add(hit));

      (Array.from(missingHitsBookingTypeId) || []).forEach(hit => {
        const missingHit = ((props || {}).hits || []).find(({ bookingTypeId }) => bookingTypeId === hit);
        if (missingHit) {
          setHits(prevHits => [...prevHits, missingHit]);
        }
      });
      const totalExperienceTiles = (injectedInfiniteHits || []).filter(({ bookingTypeId }) => !!bookingTypeId).length;
      setTilesCount(totalExperienceTiles);
    }, [injectedInfiniteHits]);

    return (
      <div>
        <Results
          {...props}
          hits={hits}
          numberOfHits={numberOfHits}
          showNoResult={showNoResult}
          hasMore={hasMore}
        />
        <br/>
        <Grid container spacing={1}>
          {(hasMore && !showNoResult) && (
            <Grid item xs={12} justifyContent={'center'} alignItems={'center'} textAlign={'center'}>
              <Button onClick={() => {
                if (hasMore) {
                  refineNext();
                }
              }} color="primary" className={'load-more-btn btn btn-primary'}>
                <Text message={'loadMore'}/>
              </Button>
            </Grid>
          )}
          {(!showNoResult && !!(injectedInfiniteHits || []).length &&
            <Grid item xs={12} justifyContent={'center'} alignItems={'center'}>
              <Typography variant="body2" textAlign={'center'}>
                <Text
                  message={'showingExperiences'}
                  values={[tilesCount, numberOfHits]}
                  // className={classes.showExperiencesText}
                />
              </Typography>
            </Grid>
          )}
        </Grid>
      </div>);
  }));
