/* eslint-disable max-lines */
import React, { useState, useRef, useMemo } from 'react';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ButtonBase from '@mui/material/ButtonBase';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Scrollbars } from 'react-custom-scrollbars';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import { Typography } from '@mui/material';
import { Text } from 'react-localize';

const styles = ({ palette, spacing, shadows, transitions }) => ({
  root: {
    padding: spacing(2)
  },
  scrollHoriz: {
    position: 'relative'
  },
  scrollNav: {
    position: 'absolute',
    top: 18,
    padding: spacing(1) / 2,
    borderRadius: '100%',
    backgroundColor: palette.background.default,
    border: `solid 1px ${palette.secondary.main}`,
    color: palette.secondary.main,
    boxShadow: shadows[3],
    zIndex: 1
  },
  scrollLeft: {
    left: 0
  },
  scrollRight: {
    right: 0
  },
  featuredFilters: {
    paddingTop: spacing(2),
    paddingBottom: spacing(2.5),
    marginLeft: spacing(1),
    width: '99%'
  },
  bigChip: {
    height: '100%',
    padding: spacing(.5),
    borderRadius: 100,
    fontSize: 14,
    fontWeight: 500
  },
  bigAvatar: {
    width: '40px !important',
    height: '40px !important',
    backgroundColor: 'transparent !important',
    transition: transitions.create('transform', {
      duration: transitions.duration.shortest
    })
  },
  bigAvatarActive: {
    transform: 'scale(1.1)'
  },
  featuredFiltersV2: {
    marginLeft: 0,
    width: '100%'
  },
  bigChipV2: {
    fontSize: spacing(2)
  },
  filterContainer: {
    width: '100%',
    marginRight: 0
  },
  scrollHorizV3: {
    '-webkit-mask-image': 'linear-gradient(to right, transparent 0, black var(--left-mask-size, 0), ' +
        'black calc(100% - var(--right-mask-size, 0)), transparent 100%)',
    '--left-mask-size': '0px',
    '--right-mask-size': '0px'
  },
  showLeftMask: {
    '--left-mask-size': `${spacing(4)}`
  },
  showRightMask: {
    '--right-mask-size': `${spacing(4)}`
  },
  centered: {
    justifyContent: 'center'
  },
  desktopImage: {
    height: '80px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  desktopContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft: spacing(2),
    paddingRight: spacing(2)
  },
  categoryOption: {
    minWidth: 'max-content',
    marginRight: spacing(1),
    marginLeft: spacing(1),
    marginBottom: spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center'

  },
  label: {
    transition: 'border-bottom 100ms',
    paddingBottom: spacing(1),
    borderBottom: '2px solid #884d4d00',
    fontSize: '10px',
    color: `${palette.text.primary} !important`,
    '&.active': {
      borderBottom: `${palette.primary.main} solid 2px`,
      color: `${palette.primary.main} !important`
    }
  },
  optionImage: {
    width: '50px',
    overflow: 'hidden'
  }
});

const FeaturedFilters = ({
  classes,
  featuredFilters,
  searchState,
  onSearchStateChange,
  isMobileView,
  tealiumTrack,
  showHierarchical,
  searchExperienceV3IsEnabled,
  sortFeaturedFiltersByDate = false,
  canResetPage = false,
  setSelectedPage
}) => {
  const [scrollValues, setScrollValues] = useState({});
  const scrollRef = useRef(null);
  const scrollUpdateHandler = value => {
    if (!isEqual(value, scrollValues)) {
      setScrollValues(value);
    }
  };
  const currentQuery = (searchState || {}).query || '';
  const delayedScrollUpdate = useMemo(() => debounce(scrollUpdateHandler, 350), [scrollValues]);
  if (!Array.isArray(featuredFilters) || !featuredFilters.length) {
    return false;
  }

  if (sortFeaturedFiltersByDate) {
    (featuredFilters || []).sort((aElement, bElement) =>
      (moment(aElement.holidayDate, 'M/DD').isBefore(moment(bElement.holidayDate, 'M/DD')) ? -1 : 1));
  }

  const currentDate = moment();

  const featuredFiltersOnOrAfterCurrentDate = [];
  const featuredFiltersBeforeCurrentDate = [];

  (featuredFilters || []).forEach(filterObj => {
    const holidayDate = moment(filterObj.holidayDate, 'M/DD');
    if (holidayDate.isSameOrAfter(currentDate, 'day')) {
      featuredFiltersOnOrAfterCurrentDate.push(filterObj);
    } else {
      featuredFiltersBeforeCurrentDate.push(filterObj);
    }
  });

  const sortedFeaturedFilters = featuredFiltersOnOrAfterCurrentDate.concat(featuredFiltersBeforeCurrentDate);
  const filtersToUse = sortFeaturedFiltersByDate ? sortedFeaturedFilters : featuredFilters;

  const removeFirst = (str, element) => {
    const index = str.indexOf(element);
    if (index === -1) {
      return str;
    }
    return [...str.slice(0, index), ...str.slice(index + 1)];
  };

  const removeSelectedQuery = (current, searchQuery) => {
    if (searchQuery && searchQuery.includes(' ')) {
      return current.replace(searchQuery, '').trim() || '';
    }
    return (removeFirst(current.split(' '), searchQuery).join(' ')
      .trim());
  };

  const maybeReplaceQuery = ({ isSelected, searchQuery }) => {
    if (!searchQuery) {
      return currentQuery;
    }
    return !isSelected ?
      (`${searchQuery || ''} ${!searchExperienceV3IsEnabled ? currentQuery : ''}`)
        .replace(/\s+/g, ' ')
        .trim() : removeSelectedQuery(currentQuery, searchQuery);
  };

  const onClickFilter = ({
    isSelected,
    searchQuery,
    refinement,
    searchTerm,
    currentValues,
    label,
    filter
  }) => {
    if (!canResetPage && setSelectedPage) {
      setSelectedPage(1);
    }
    onSearchStateChange({
      ...searchState,
      query: maybeReplaceQuery({ isSelected, searchQuery }),
      ...!showHierarchical && {
        refinementList: {
          ...searchState.refinementList,
          [refinement]: isSelected ?
            currentValues.filter(val => !searchTerm.includes(val))
            : [
              ...currentValues,
              ...searchTerm
            ]
        }
      },
      ...showHierarchical && {
        hierarchicalMenu: {
          ...searchState.hierarchicalMenu,
          [refinement]: isSelected ? '' : searchTerm[0]
        }
      },
      ...canResetPage && {
        page: 1
      }
    });
    if (typeof tealiumTrack === 'function') {
      tealiumTrack({
        name: 'search',
        category: 'search',
        action: `featured_filter_${isSelected ? 'remove' : 'add'}`,
        label: label || filter
      });
    }
  };

  return (
    <div className={classNames({
      [classes.scrollHoriz]: true,
      [classes.scrollHorizV3]: searchExperienceV3IsEnabled && isMobileView,
      [classes.showLeftMask]: searchExperienceV3IsEnabled && isMobileView && scrollValues.left > 0,
      [classes.showRightMask]: searchExperienceV3IsEnabled && isMobileView && scrollValues.left < 1
        && scrollValues.scrollWidth > scrollValues.clientWidth,
      [classes.scrollHorizMobile]: isMobileView,
      [classes.centered]: !isMobileView
    }, 'featured-filters-container')}>
      {!isMobileView && scrollValues.left > 0 && (
        <ButtonBase
          className={classNames({
            [classes.scrollNav]: true,
            [classes.scrollLeft]: true
          })}
          onClick={() => scrollRef.current.view.scroll({
            left: scrollValues.scrollLeft - scrollValues.clientWidth,
            behavior: 'smooth'
          })}
        >
          <ChevronLeftIcon color={'inherit'} fontSize={'small'}/>
        </ButtonBase>
      )}
      <Scrollbars
        style={{ width: '100%' }}
        autoHeight
        autoHide
        onUpdate={delayedScrollUpdate}
        renderTrackVertical={() => <div/>}
        renderThumbVertical={() => <div/>}
        ref={scrollRef}
      >
        <div
          className={classNames({
            [classes.featuredFilters]: true,
            [classes.featuredFiltersV2]: searchExperienceV3IsEnabled
          })}
        >
          <Grid
            container
            spacing={searchExperienceV3IsEnabled ? 2 : 1}
            wrap={'nowrap'}
            className={classNames({
              [classes.filterContainer]: searchExperienceV3IsEnabled
            })}
          >
            {(filtersToUse || []).map(({
              searchQuery,
              refinement,
              label,
              filter,
              img,
              activeImg
            }) => {
              const safeSearchTerm = filter || label;
              const searchTerm = Array.isArray(safeSearchTerm) ? safeSearchTerm : [safeSearchTerm];
              const currentValues = (searchState.refinementList || {})[refinement] || [];
              if (showHierarchical) {
                currentValues.push((searchState.hierarchicalMenu || {})[refinement] || '');
              }
              const isSelected = searchTerm.every(val => currentValues.includes(val));
              return (
                <Grid item key={`featured-filter-${refinement}-${searchTerm}`}>
                  {activeImg ?
                    <div
                      className={classNames({
                        [classes.desktopContainer]: !isMobileView,
                        [classes.categoryOption]: isMobileView
                      })}
                      onClick={() => onClickFilter({
                        isSelected,
                        searchQuery,
                        refinement,
                        searchTerm,
                        currentValues,
                        label,
                        filter })}>
                      <img draggable={false} className={classNames({
                        [classes.desktopImage]: !isMobileView,
                        [classes.optionImage]: isMobileView })} src={isSelected ? activeImg : img}/>
                      <Typography variant="body2" className={classNames({
                        ['active']: isSelected && isMobileView,
                        [classes.label]: isMobileView
                      })}>
                        <Text message={label}/>
                      </Typography>
                    </div>
                    :
                    <Chip
                      className={classNames({
                        [classes.bigChip]: true,
                        [classes.bigChipV2]: searchExperienceV3IsEnabled
                      })}
                      avatar={!!img && (
                        <Avatar
                          className={classNames({
                            [classes.bigAvatar]: true,
                            [classes.bigAvatarActive]: isSelected
                          })}
                          alt="filter"
                          src={img}
                        />
                      )}
                      label={label || filter}
                      variant={isSelected ? 'contained' : 'outlined'}
                      clickable
                      color={'primary'}
                      onClick={() => onClickFilter({
                        isSelected,
                        searchQuery,
                        refinement,
                        searchTerm,
                        currentValues,
                        label,
                        filter })}
                    />
                  }
                </Grid>
              );
            })}
          </Grid>
        </div>
      </Scrollbars>
      {!isMobileView && scrollValues.left < 1
          && scrollValues.scrollWidth > scrollValues.clientWidth && (
        <ButtonBase
          className={classNames({
            [classes.scrollNav]: true,
            [classes.scrollRight]: true
          })}
          onClick={() => scrollRef.current.view.scroll({
            left: scrollValues.scrollLeft + scrollValues.clientWidth,
            behavior: 'smooth'
          })}
        >
          <ChevronRightIcon color={'inherit'} fontSize={'small'}/>
        </ButtonBase>
      )}
    </div>
  );
};

FeaturedFilters.contextTypes = {
  localize: PropTypes.func.isRequired
};

export default withStyles(styles)(FeaturedFilters);
